import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import LoginCard from '@components/ka-ui/login-card/LoginCard';
import EmailForm from '@components/auth-components/login/username-password-form/UsernamePasswordForm';
import { getRedirectQuery } from '@utils/helpers';
import RedirectContext, { RedirectType } from '@contexts/RedirectContext';

const Login: React.FC = () => {
  const router = useRouter();
  const [, setRedirect] = useContext(RedirectContext);

  useEffect(() => {
    const redirectQuery: RedirectType | undefined = getRedirectQuery(router.query);
    if (redirectQuery) {
      setRedirect(redirectQuery);
    }
  }, [router, setRedirect]);

  return (
    <LoginCard title="" subtitle="" buttonText={undefined}>
      <EmailForm />
    </LoginCard>
  );
};

export default Login;
