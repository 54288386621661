import React, { useContext, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import UiButton from '@components/ui/button/UiButton';
import IntroErrorContext from '@contexts/IntroErrorContext';
import { loginUser } from '@services/login/login-http.service';
import StateEnum from '@constants/state.enum';
import useNotification from '@hooks/notification/useNotification';
import styles from './UsernamePasswordForm.module.scss';

const UsernamePasswordForm: React.FC = () => {
  const { t: translate, i18n } = useTranslation(['common', 'login']);
  const [form] = Form.useForm();
  const [, setErrors] = useContext(IntroErrorContext);
  const [loading, setLoading] = useState(false);

  const [unableLogin] = useNotification(
    StateEnum.ERROR,
    'Something went wrong',
    'We cannot find you username or password, please try again.',
  );

  useEffect(() => {
    setErrors({ errorMessages: [] });
  }, [setErrors]);

  /**
   * Handles log in.
   * @param values
   */
  type LoginValues = {
    username: string;
    password: string;
  };
  const handleOnFinish = async (values: LoginValues) => {
    try {
      setLoading(true);
      await loginUser(values.username, values.password);
      window.location.assign(`/${i18n.language}/app/home`);
    } catch (requestError: any) {
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      requestError.ErrorMessage === 'invalid_login' && unableLogin('Invalid credentials');
    }
  };

  return (
    <>
      <Form
        name="login_email"
        className={`${styles.compWrap} antd-overwrite`}
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
      >
        <Form.Item
          name="username"
          label={translate('login:username_label')}
          required={false}
          rules={[
            {
              required: true,
              message: translate('login:email_required'),
            },
            {
              max: 255,
              message: translate('login:too_many_characters'),
            },
          ]}
        >
          <Input data-testid="login-email" placeholder={translate('login:email_placeholder')} />
        </Form.Item>
        <Form.Item
          name="password"
          label={translate('login:password_label')}
          required={false}
          rules={[
            {
              required: true,
              message: translate('login:password_required'),
            },
            {
              max: 255,
              message: translate('login:too_many_characters'),
            },
          ]}
        >
          <Input.Password placeholder={translate('login:password_placeholder')} data-testid="login-password" />
        </Form.Item>
        <Form.Item>
          <UiButton htmlType="submit" type="primary" block disabled={loading}>
            {translate('login:login_now')}
          </UiButton>
        </Form.Item>
      </Form>
      <span className={`large-body-text ${styles.footer}`}>{translate('login:forgot_password')}</span>
      <Link href="/forgot-password" role="button" tabIndex={0} className="h5" data-testid="forgot-password-link">
        {translate('login:password_recover')}
      </Link>
    </>
  );
};

export default UsernamePasswordForm;
